<script setup lang="ts">
import { useImage } from '#image/composables'
import { computed } from 'vue'

const props = withDefaults(defineProps<{
  icons?: {
    icon: string
    title: string
    subtitle: string
  }[]
  bgImgUrl: string
  bgRoueUrl?: string
  theme?: keyof typeof themes
  subDivClasses?: string
  leftBgPosClass?: string
  compact?: boolean
}>(), {
  icons: () => [],
  bgRoueUrl: '/img/eliah_picto-violetclair.png',
  theme: 'lemonToSunset',
  leftBgPosClass: 'bg-top-right',
  compact: true,
})

const themes = {
  transparent: 'theme-transparent',
  lemonToSunset: 'theme-lemon-to-sunset',
}

const themeClass = computed(() => themes[props.theme])

const $img = useImage()

const cssBgImgUrl = computed(() => `url(${$img(props.bgImgUrl, {
  quality: 80,
  format: 'webp',
  height: 600,
})})`)

const cssBgRoueUrl = computed(() => `url(${$img(props.bgRoueUrl, {
  quality: 80,
  format: 'webp',
  height: 600,
})})`)

const icons = computed(() => {
  const icons = []
  for (const icon of props.icons) {
    if (icon.icon.endsWith('.svg')) {
      icons.push({
        ...icon,
        component: 'img',
      })
    }
    else {
      icons.push({
        ...icon,
        component: resolveComponent('NuxtImg'),
      })
    }
  }

  return icons
})
</script>

<template>
  <div class="relative w-full flex flex-col bg-void lg:bg-white">
    <div
      class="bg-img absolute h-full w-45% rounded-br-80 bg-cover lt-lg:hidden"
      :class="{ [leftBgPosClass!]: true }"
    >
      <div class="h-full rounded-br-80 bg-opacity-70" :class="themeClass">
        <div class="h-full" />
      </div>
    </div>

    <div class="w-full flex flex-col py-14" :class="subDivClasses">
      <div class="void-cover w-full flex items-center justify-center">
        <div
          class="bg-roue z-10 flex bg-void lg:rounded-bl-[100px] lg:rounded-tl-[100px] xl:rounded-bl-full lt-lg:pa-4 lt-md:pa-2"
          :class="{
            'compact-container': compact,
            'container': !compact,
          }"
        >
          <div class="z-20 h-full w-full flex grow flex-col items-end justify-center bg-void bg-opacity-85 lg:min-h-[610px] xl:min-h-[500px] lg:justify-start xl:justify-center lg:rounded-bl-[100px] lg:rounded-tl-[100px] xl:rounded-bl-full lt-lg:pa-2">
            <div
              class="w-full text-justify lg:py-20"
              :class="{
                '2xl:max-w-[750px] lg:max-w-[500px] xl:max-w-[620px]': compact,
                '2xl:max-w-[900px] lg:max-w-[650px] xl:max-w-[770px]': !compact,
              }"
            >
              <h1 class="mb-6 text-2xl text-lavender leading-10">
                <slot name="title" />
              </h1>

              <div class="pr-4 leading-8 font-gravity space-y-2 children:leading-8">
                <slot name="paragraph" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="w-full flex items-center justify-center">
        <div
          class="flex items-center justify-center lg:justify-end"
          :class="{
            'compact-container': compact,
            'container': !compact,
          }"
        >
          <div
            v-if="icons"
            class="relative z-100 min-h-40 flex flex-col items-center justify-center rounded-b-10 md:flex-row md:items-start lg:justify-center lg:lt-xl:bg-white lg:lt-xl:bg-opacity-50 lg:lt-xl:backdrop-blur-8"
            :class="{
              'gap-4 lg:gap-0 xl:gap-5 2xl:gap-15': icons.length < 4 && compact,
              'gap-4 lg:gap-0 xl:gap-4': icons.length >= 4 && compact,
              'gap-10 lg:gap-0 xl:gap-15': icons.length < 4 && !compact,
              'gap-10 lg:gap-0 2xl:gap-8': icons.length >= 4 && !compact,
            }"
          >
            <div
              v-for="icon in icons"
              :key="icon.title"
              class="flex flex-col items-center"
              :class="{
                'w-40 lg:w-35 xl:w-40': compact,
                'w-50 lg:w-45 2xl:w-50': !compact,
              }"
            >
              <div class="h-20 w-[3px] bg-sunset-100" />

              <div class="bg-lavender-250 pa-3">
                <component
                  :is="icon.component"
                  class="h-14 w-14 lg:h-10 lg:w-10 xl:h-14 xl:w-14"
                  :src="icon.icon"
                  format="webp"
                  quality="80"
                  sizes="sm:40px md:50px lg:60px xl:70px"
                  alt="Montagne lac violet"
                />
              </div>

              <h3 class="lg:text-md mt-4 text-center text-lg text-black font-semibold leading-8 xl:text-lg">
                <span class="text-lavender tracking-widest font-gravity uppercase">{{ icon.title }}</span><br>
                <span class="font-gravity lt-lg:text-lavender-200">{{ icon.subtitle }}</span>
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.bg-img {
  background-image: v-bind('cssBgImgUrl');
  background-position: center;
}

.void-cover {
  background: linear-gradient(45deg, #292043 50%, #292043 50%);
}

@media (min-width: 1024px) {
  .void-cover {
    background: linear-gradient(to right, white 50%, #292043 50%);
  }
}

@media (min-width: 1024px) {
  .bg-roue {
    background-image: v-bind('cssBgRoueUrl');
    background-repeat: no-repeat;
    background-size: 400px;
    background-position: -200px 100px;
  }
}

@media (min-width: 1280px) {
  .void-cover {
    background: linear-gradient(45deg, white 50%, #292043 50%);
  }

  .bg-roue {
    background-size: 500px;
    background-position: -150px 100px;
  }
}

@media (min-width: 1600px) {
  .bg-roue {
    background-size: 500px;
    background-position: -120px 100px;
  }
}

.bg-img > .theme-lemon-to-sunset {
  background-image: linear-gradient(to bottom, rgba(252, 234, 16, 0.7), rgba(233, 164, 0, 0.7));
}
</style>
